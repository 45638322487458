import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout';
import Map from '../components/Map/Map';
import RevertPadding from '../components/RevertPadding';

export const frontmatter = {
  title: 'Kontakt',
  menu: 6,
};

export default () => (
  <Layout>
    <Helmet>
      <title>Kontakt</title>
    </Helmet>
    <h1>Kontakt</h1>
    <p>
      Hirsch-Apotheke Korbach
      <br />
      Inh. Pia Ueberson
      <br />
      Prof.-Kümmell-Straße 2<br />
      34497 Korbach
    </p>
    <p>
      Tel: 05631 / 25 45
      <br />
      Fax: 05631 / 913 923
      <br />
      E-Mail: kontakt@hirsch-apotheke-korbach.de
    </p>
    <h2>Anfahrt</h2>
    <RevertPadding>
      <Map
        markers={[
          {
            lat: 51.2732954,
            long: 8.8713331,
            popup: (
              <>
                <h6>Hirsch-Apotheke</h6>
                <p>
                  Prof.-Kümmell-Str. 2<br />
                  34497 Korbach
                </p>
                <p>Tel.: 05631 / 2545</p>
              </>
            ),
            tooltip: 'Hirsch-Apotheke',
          },
        ]}
        zoom={17}
      />
    </RevertPadding>
  </Layout>
);
